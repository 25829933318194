import { default as _91id_93I2LARd19GGMeta } from "/usr/src/nuxt-app/pages/card/[id].vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as loginlzkgvgJgWgMeta } from "/usr/src/nuxt-app/pages/login.vue?macro=true";
import { default as indext6cu0RBCUJMeta } from "/usr/src/nuxt-app/pages/search/index.vue?macro=true";
export default [
  {
    name: _91id_93I2LARd19GGMeta?.name ?? "card-id___en",
    path: _91id_93I2LARd19GGMeta?.path ?? "/en/card/:id()",
    meta: _91id_93I2LARd19GGMeta || {},
    alias: _91id_93I2LARd19GGMeta?.alias || [],
    redirect: _91id_93I2LARd19GGMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/card/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93I2LARd19GGMeta?.name ?? "card-id___de",
    path: _91id_93I2LARd19GGMeta?.path ?? "/card/:id()",
    meta: _91id_93I2LARd19GGMeta || {},
    alias: _91id_93I2LARd19GGMeta?.alias || [],
    redirect: _91id_93I2LARd19GGMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/card/[id].vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___en",
    path: indexAoBMx6PnC1Meta?.path ?? "/en",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___de",
    path: indexAoBMx6PnC1Meta?.path ?? "/",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginlzkgvgJgWgMeta?.name ?? "login___en",
    path: loginlzkgvgJgWgMeta?.path ?? "/en/login",
    meta: loginlzkgvgJgWgMeta || {},
    alias: loginlzkgvgJgWgMeta?.alias || [],
    redirect: loginlzkgvgJgWgMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginlzkgvgJgWgMeta?.name ?? "login___de",
    path: loginlzkgvgJgWgMeta?.path ?? "/login",
    meta: loginlzkgvgJgWgMeta || {},
    alias: loginlzkgvgJgWgMeta?.alias || [],
    redirect: loginlzkgvgJgWgMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indext6cu0RBCUJMeta?.name ?? "search___en",
    path: indext6cu0RBCUJMeta?.path ?? "/en/search",
    meta: indext6cu0RBCUJMeta || {},
    alias: indext6cu0RBCUJMeta?.alias || [],
    redirect: indext6cu0RBCUJMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indext6cu0RBCUJMeta?.name ?? "search___de",
    path: indext6cu0RBCUJMeta?.path ?? "/search",
    meta: indext6cu0RBCUJMeta || {},
    alias: indext6cu0RBCUJMeta?.alias || [],
    redirect: indext6cu0RBCUJMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/search/index.vue").then(m => m.default || m)
  }
]